import { useCallback, useEffect, useState } from 'react'
import { OpenIDContext, codeResponse } from '../../api/auth'
import idBackendRequestHandler from '../../api/idBackendRequestHandler'
import playBackendRequestHandler from '../../api/playBackendRequestHandler'
import { GameMode, PlayerType } from '../../enums'

function OpenIDContextProvider({ className, children }: DefaultProps) {
	const code = new URLSearchParams(window.location.search).get('code')
	const subscriptionCheckout = new URLSearchParams(window.location.search).get(
		'subscription-checkout',
	)
	const [isGlobalAlertShown, setIsGlobalAlertShown] = useState(false)
	const [gameAndChallengeRestriction, setGameAndChallengeRestriction] = useState(
		{
			isGameUnrestricted: false,
			isChallengeUnrestricted: false,
		},
	)
	const [profile, setProfile] = useState({
		authenticated: null as boolean | null,
		profile: {
			firstName: '',
			lastName: '',
			username: '',
			profileImage: '',
			email: '',
			location: '',
			dateJoined: '',
			isSubscribed: false,
			subscriptionType: '',
			lastActive: '',
			playerTotalGameScore: 0,
			playerBackground: {
				company: '',
				company_logo: '',
				can_add_company_game: false,
				hasPlayerCompanyDashboardAccess: false,
				playerType: PlayerType.General,
			},
			restrictions: {
				subscribedAttempts: 0,
				subscribedRemainingAttempts: 0,
				hasPrivateGameAccess: false,
				hasPrivateChallengeAccess: false,
				hasPrivateHintAccess: false,
				hasBattleChallengeAccess: false,
				hasBattleChallengeSourceCodeAccess: false,
				hasCSEAccess: false,
				hasSandboxAccess: false,
			},
		},
	})

	const userInfo = useCallback(async () => {
		if (code) {
			codeResponse(window.location.href)
				.then(resp => {
					const idToken = resp.id_token // User's 'passport'
					const newURL = window.location.href.split('?')[0]
					playBackendRequestHandler('sessionLogin', undefined, idToken)
						.then(response => {
							if (response.status === 200) {
								if (sessionStorage.getItem('redirectURI')) {
									window.location.href = sessionStorage.getItem('redirectURI')!
									sessionStorage.removeItem('redirectURI')
								} else {
									window.history.pushState('object', document.title, newURL)
								}
							}
						})
						.catch(() => {
							window.history.pushState('object', document.title, newURL)
						})
				})
				.catch(err => {
					// eslint-disable-next-line no-console
					console.error(err)
				})
		}

		playBackendRequestHandler('playUserInfo').then(playUserInfo => {
			if (playUserInfo.data.logged_in) {
				idBackendRequestHandler(
					'idUserInfo',
					undefined,
					undefined,
					`${playUserInfo.data.username}/info/`,
				).then(idUserInfo => {
					if (idUserInfo.status === 200) {
						const profileUpdate = {
							authenticated: true,
							profile: {
								firstName: idUserInfo.data.first_name,
								lastName: idUserInfo.data.last_name,
								username: playUserInfo.data.username,
								profileImage: idUserInfo.data.profile_image,
								email: '',
								location: idUserInfo.data.location,
								dateJoined: idUserInfo.data.date_joined,
								isSubscribed: playUserInfo.data.subscription,
								subscriptionType: playUserInfo.data.subscription_type,
								lastActive: playUserInfo.data.last_active,
								playerTotalGameScore: playUserInfo.data.player_total_game_score,
								playerBackground: {
									company: idUserInfo.data.company,
									company_logo: idUserInfo.data.company_logo,
									can_add_company_game: idUserInfo.data.can_add_company_game,
									hasPlayerCompanyDashboardAccess:
										playUserInfo.data.player_background
											.has_player_company_dashboard_access,
									playerType: playUserInfo.data.player_background.player_type,
								},
								restrictions: {
									subscribedAttempts: playUserInfo.data.restrictions.subscribed_attempts,
									subscribedRemainingAttempts:
										playUserInfo.data.restrictions.subscribed_remaining_attempts,
									hasPrivateGameAccess:
										playUserInfo.data.restrictions.has_private_game_access,
									hasPrivateChallengeAccess:
										playUserInfo.data.restrictions.has_private_challenge_access,
									hasPrivateHintAccess:
										playUserInfo.data.restrictions.has_private_hint_access,
									hasBattleChallengeAccess:
										playUserInfo.data.restrictions.has_battle_challenge_access,
									hasBattleChallengeSourceCodeAccess:
										playUserInfo.data.restrictions
											.has_battle_challenge_source_code_access,
									hasCSEAccess: playUserInfo.data.restrictions.has_cse_access,
									hasSandboxAccess: playUserInfo.data.restrictions.has_sandbox_access,
								},
							},
						}
						if (sessionStorage.getItem('redirectURI') && subscriptionCheckout) {
							sessionStorage.setItem('subscriptionCheckout', subscriptionCheckout)
							window.location.href = sessionStorage.getItem('redirectURI')!
							sessionStorage.removeItem('redirectURI')
						}
						if (
							profileUpdate.authenticated &&
							profileUpdate.profile.playerBackground.playerType ===
								PlayerType.Exclusive
						) {
							sessionStorage.setItem('rootRedirect', GameMode.Company)
						} else if (
							(profileUpdate.authenticated &&
								profileUpdate.profile.playerBackground.playerType ===
									PlayerType.General) ||
							profileUpdate.authenticated === false
						) {
							sessionStorage.setItem('rootRedirect', GameMode.Single)
						}
						setProfile(profileUpdate)
					}
				})
			} else {
				setProfile(prevState => ({
					...prevState,
					authenticated: false,
				}))
			}
		})
	}, [code, subscriptionCheckout])

	useEffect(() => {
		userInfo()
	}, [userInfo])

	return (
		<div className={className}>
			<OpenIDContext.Provider
				// eslint-disable-next-line react/jsx-no-constructed-context-values
				value={{
					authenticated: profile.authenticated,
					profile: profile.profile,
					refresh: userInfo,
					isGlobalAlertShown,
					setIsGlobalAlertShown,
					gameAndChallengeRestriction,
					setGameAndChallengeRestriction,
				}}
			>
				{children}
			</OpenIDContext.Provider>
		</div>
	)
}
export default OpenIDContextProvider
