import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

// eslint-disable-next-line import/prefer-default-export
export const TableBox = styled(Box)(({ theme }) => ({
	padding: '0 56px',
	paddingRight: '0',

	"& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
		outline: "none !important",
	},

	'& .MuiDataGrid-columnHeader:last-child': {
		'& .MuiDataGrid-columnSeparator': {
			display: 'none',
		},
	},

	'& .MuiDataGrid-footerContainer': {
		height: '40px !important',
		minHeight: '0px'
	},

	'& .css-7g6wwl-MuiInputBase-root-MuiTablePagination-select': {
		marginRight: '8px',
	},


	[theme.breakpoints.down('sm')]: {
		padding: 0,
	},

	'& .MuiDataGrid-row': {
		cursor: 'pointer'
	},
}))

