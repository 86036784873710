
import React, { useState } from 'react'
import { Grid } from '@mui/material'
import * as Styled from './CompanyDashboardInfoStyles'
import Tooltip from '../../../../shared/components/Tooltip/Tooltip'
import { getGradeAndEfficiencyLevel } from '../../../../shared/helpers/utils'

const mediaCard = (content: any) => <Styled.MediaCard>
    <Styled.MediaCardContent>
        {content}
    </Styled.MediaCardContent>
</Styled.MediaCard>

const companyComparativeGradeFrontAndBackCard = (cardSideClassName: string, companyGradeTitle: string, companyComparativeGradeData: any, overallGradeAndEfficiencyLevel: any, onHoverFlip: any) => <Styled.CompanyComparativeGradeCard className={cardSideClassName}>
    <Styled.MediaCardNameContainer>
        <Styled.MediaCardName>
            {companyGradeTitle}
            <Tooltip title="Company Comparative Grade: Your team's engagement and skill level in compare with other teams. Grade is calculated based on Z score and percentile rank. It shows how many standard deviations your team's score is away from the mean score of all other teams."
                placement='top-start'>
                <Styled.HelpIcon />
            </Tooltip>
        </Styled.MediaCardName>
    </Styled.MediaCardNameContainer>
    <Tooltip title={`Total score: ${companyComparativeGradeData.totalScore}, Z-score: ${companyComparativeGradeData.zIndex}`}
        placement='top-start'>
        <Styled.MediaCardBody>{overallGradeAndEfficiencyLevel.grade || "-"}</Styled.MediaCardBody>
    </Tooltip>
    <Styled.CompanyEfficiencyLevel>{overallGradeAndEfficiencyLevel.efficiencyLevel}</Styled.CompanyEfficiencyLevel>
    <Styled.CustomArrowForward onClick={onHoverFlip} />
</Styled.CompanyComparativeGradeCard>


function CompanyDashboardInfo({ companyPlayersActivitiesData, companyComparativeGradeData }: CompanyDashboardInfoProps) {
    const overallGradeAndEfficiencyLevel = getGradeAndEfficiencyLevel(companyComparativeGradeData.overall_company_comparative_z_score, companyComparativeGradeData.overall_company_comparative_percentile_rank)
    const lastMonthGradeAndEfficiencyLevel = getGradeAndEfficiencyLevel(companyComparativeGradeData.last_month_company_comparative_z_score, companyComparativeGradeData.last_month_company_comparative_percentile_rank)
    const totalNumberOfAttempts = companyPlayersActivitiesData && companyPlayersActivitiesData.player_challenge_attempts ? companyPlayersActivitiesData.player_challenge_attempts.reduce((partialSum, attempt) => partialSum + attempt.total_challenge_attempts, 0) : 0
    const [flip, setFlip] = useState(false)

    const onHoverFlip = () => setFlip(!flip)

    return <Grid container spacing={2}>
        <Styled.CompanyComparativeGradeCardWrapper className={`${flip ? "flipped" : ""}`} item xs={12} md={4}>
            {
                mediaCard(
                    <>
                        {
                            companyComparativeGradeFrontAndBackCard(
                                "companyComparativeGradeFrontCard",
                                "Overall Grade",
                                {
                                    "totalScore": companyComparativeGradeData.overall_company_comparative_total_score,
                                    "zIndex": companyComparativeGradeData.overall_company_comparative_z_score
                                },
                                {
                                    "grade": overallGradeAndEfficiencyLevel.grade,
                                    "efficiencyLevel": overallGradeAndEfficiencyLevel.efficiencyLevel
                                },
                                onHoverFlip
                            )
                        }
                        {
                            companyComparativeGradeFrontAndBackCard(
                                "companyComparativeGradeBackCard",
                                "Last Month Grade",
                                {
                                    "totalScore": companyComparativeGradeData.last_month_company_comparative_total_score,
                                    "zIndex": companyComparativeGradeData.last_month_company_comparative_z_score
                                },
                                {
                                    "grade": lastMonthGradeAndEfficiencyLevel.grade,
                                    "efficiencyLevel": lastMonthGradeAndEfficiencyLevel.efficiencyLevel
                                },
                                onHoverFlip
                            )
                        }
                    </>
                )
            }
        </Styled.CompanyComparativeGradeCardWrapper>
        <Grid item xs={12} md={4}>
            {
                mediaCard(<>
                    <Styled.MediaCardNameContainer><Styled.MediaCardName>Total number of attempts</Styled.MediaCardName></Styled.MediaCardNameContainer>
                    <Styled.MediaCardBody>{totalNumberOfAttempts}</Styled.MediaCardBody>
                </>)
            }
        </Grid>
        <Grid item xs={12} md={4}>
            {
                mediaCard(<>
                    <Styled.MediaCardNameContainer><Styled.MediaCardName>Number of licenses used</Styled.MediaCardName></Styled.MediaCardNameContainer>
                    <Styled.MediaCardBody>{companyPlayersActivitiesData.number_of_subscribed_players}</Styled.MediaCardBody>
                </>)
            }
        </Grid>
    </Grid>
}

export default CompanyDashboardInfo