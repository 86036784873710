/* eslint-disable no-nested-ternary */
import { useState } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import * as Styled from './CompanyPlayersActivitiesTableStyles'
import { useCompanyDashboardContext } from '../../../../api/hooks'


const columns: GridColDef[] = [
	{
		field: 'id',
		headerName: 'Rank',
		align: 'center',
		headerAlign: 'center',
		flex: 75,
	},
	{ field: 'playerEmail', headerName: 'Player', flex: 150 },
	{
		field: 'attempts',
		headerName: 'Attempts',
		type: 'number',
		align: 'center',
		headerAlign: 'center',
		flex: 75,
	},
]


function CompanyPlayersActivitiesTable({ className, companyPlayersActivitiesData }: CompanyPlayersActivitiesTableProps) {
	const { setOpenCompanyPlayerProfileDialogInfo } = useCompanyDashboardContext()
	const [pageSize, setPageSize] = useState(15)
	const rows: { id: string; playerEmail: string; attempts: number }[] = []


	const onClickTableRow = (rowData: any) => {
		const companyPlayerActivities = companyPlayersActivitiesData.find((data) => data.player_email === rowData.row.playerEmail)
		setOpenCompanyPlayerProfileDialogInfo({
			isDialogShown: true,
			playerEmail: companyPlayerActivities!.player_email,
			playerUsername: companyPlayerActivities!.player_username,
		})
	}

	for (let index = 0; index < companyPlayersActivitiesData.length; index += 1) {
		let rank: string
		if (index === 0) rank = '🥇'
		else if (index === 1) rank = '🥈'
		else if (index === 2) rank = '🥉'
		else rank = String(index + 1)
		rows.push({
			id: rank,
			playerEmail: companyPlayersActivitiesData[index].player_email,
			attempts: companyPlayersActivitiesData[index].total_challenge_attempts,
		})
	}

	const handlePageSizeChange = (params: any) => {
		setPageSize(params.pageSize)
	}

	return (
		<Styled.TableBox className={className}>
			<DataGrid
				rows={rows}
				columns={columns}
				onRowCountChange={handlePageSizeChange}
				pageSizeOptions={[15, 25, 50, 100]}
				autoHeight
				rowHeight={40}
				localeText={{
					MuiTablePagination: {
						labelRowsPerPage: '',
					},
				}}
				columnHeaderHeight={40}
				pagination
				onRowClick={onClickTableRow}
				initialState={{
					pagination: {
						paginationModel: { pageSize },
					},
				}}
			/>
		</Styled.TableBox>
	)
}
export default CompanyPlayersActivitiesTable
