import { Close, Info } from '@mui/icons-material'
import { Box, DialogTitle, Grid, Dialog, Button, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const SecdimBlue = styled('span')(({ theme }) => ({
	color: `${theme.palette.primary.main}!important`,
	fontWeight: 'bold',
}))

export const CustomDialog = styled(Dialog)({

})

export const PlayerActivityGridDialogItem = styled(Grid)({
})

export const PlayerRankingsTitle = styled(Typography)(({ theme }) => ({
	marginTop: 10,
	padding: '0px 0px 10px 75px',
	fontWeight: 600,
	textAlign: 'center',
	fontSize: '13.5px',
	color: '#464343',
	position: 'relative',

	[theme.breakpoints.down('sm')]: {
		padding: '0px 0px 10px 0px',
		textAlign: 'center',
		marginTop: 0,
	},
}))

export const PlayerActivityGraphTableContainer = styled(Grid)(() => ({
	margin: '10px 0px 15px 0px',
	width: '900px !important'
}))

export const PlayerActivityGraphTableItem = styled(Grid)(() => ({

}))


export const SquareColorLabelWrapper = styled(Box)({
	display: 'flex',
	position: 'absolute',
	top: '140px',
	left: '44px'
})

export const SquareColorLabel = styled(Box, {
	shouldForwardProp: (prop) => prop !== "backgroundColor"
})<{ backgroundColor: string }>(
	({ backgroundColor }) => ({
		height: 14.71,
		width: 14.71,
		backgroundColor,
		marginRight: '1.5px'
	})
)

export const DialogTitleText = styled(DialogTitle)({
	fontWeight: 'bold',
	textAlign: 'center',
	fontSize: '1.875rem',
	lineHeight: 1.235,
})

export const ButtonWrapper = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
})

export const GitActivities = styled(Typography)(({ theme }) => ({
	position: 'absolute',
	right: 0,
	top: '138px',
	fontSize: '12px',

	[theme.breakpoints.down('sm')]: {
		right: 40,
	},
}))

export const PlayerActivityDialogContainer = styled(Grid)(({ theme }) => ({
	width: 1000,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: 40,

	[theme.breakpoints.down('sm')]: {
		width: 'auto',
		height: 'auto',
	},
}))

export const UserNavLink = styled('a')(({ theme }) => ({
	color: theme.palette.primary.main,
	textDecoration: 'none',
	'&:hover': {
		textDecoration: 'underline',
	}
}))

export const AbsoluteClose = styled(Close)({
	position: 'absolute',
	top: 20,
	right: 20,
	borderRadius: 10,
	cursor: 'pointer',
})

export const CustomCalendarHeatmapWrapper = styled(Box)(({ theme }) => ({
	position: 'relative',
	'& .react-calendar-heatmap': {
		width: '900px !important',
	},

	[theme.breakpoints.down('sm')]: {
		'& .react-calendar-heatmap': {
			marginRight: '40px'
		},
	},
}))

export const RemoveUserButton = styled(Button)({
	padding: '6px 14px',
	borderColor: '#ff000073',
	backgroundColor: '#FFF',
	color: '#ff0000de',
	'&:hover': {
		color: '#FFF',
		backgroundColor: '#ff0000de',
		borderColor: '#ff000073',
	}
})

export const HelpIcon = styled(Info)(({ theme }) => ({
	color: theme.palette.primary.main,
	fontSize: '13.5px',
	position: 'absolute',
	top: '3px',
	marginLeft: '3px',
	cursor: 'pointer'
}));
