// the following code is commented (used for subscription checkout call in ID) but since,
// id session period is limited, we are using the following code

import axios, { AxiosResponse, Method } from 'axios'
import { OperationResponse, operations } from './operations'
import { urls } from './urls'

export const getCSRFToken = () => {
	const cookies = document.cookie.split('; ')
	const csrfTokenIndex = cookies.findIndex(item =>
		item.startsWith('id_csrftoken'),
	)

	if (csrfTokenIndex >= 0) {
		return cookies[csrfTokenIndex].replace('id_csrftoken=', '')
	}

	return ''
}

export default async function playBackendRequestHandler<T extends operations>(
	operation: T,
	data?: any,
	token?: string,
	appendURL?: string,
): Promise<AxiosResponse<OperationResponse[T]>> {
	const endpointDetails = urls[operation]
	const csrfToken = getCSRFToken()
	let url = `${process.env.REACT_APP_DJANGO_SECDIM_ID}${endpointDetails.endpoint}`
	if (appendURL) {
		url = `${process.env.REACT_APP_DJANGO_SECDIM_ID}${endpointDetails.endpoint}${appendURL}`
	}
	if (endpointDetails) {
		const headers: any = {
			Accept: 'application/json',
			'X-CSRFToken': csrfToken,
			'Content-type': 'application/json',
		}
		if (token) {
			headers.Authorization = `Bearer ${token}`
		}
		return axios({
			method: endpointDetails.type as Method,
			withCredentials: true,
			headers,
			url,
			data,
		})
	}
	throw new Error('Invalid Operation')
}
