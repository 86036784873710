import React, { useState } from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import * as Styled from './CompanyPlayersActivitiesFilterStyles'
import { TimePeriod } from '../../../../enums';

const timePeriods = [TimePeriod.Last7Days, TimePeriod.Last30Days, TimePeriod.Last90Days, TimePeriod.Last6Months, TimePeriod.Last1Year]

function CompanyPlayersActivitiesFilter({ processSelectedTimePeriod }: CompanyPlayersActivitiesFilterProps) {
    const [selectedTimePeriod, setSelectedTimePeriod] = useState(String(TimePeriod.Default));
    const onClickTimePeriod = (event: SelectChangeEvent) => {
        processSelectedTimePeriod(event.target.value)
        setSelectedTimePeriod(event.target.value)
    }
    return <Styled.CustomFormControl>
        <Styled.CustomInputLabel id="time-period-filter-label">Date Range</Styled.CustomInputLabel>
        <Select
            labelId="time-period-filter-label"
            id="time-period-filter-select-id"
            value={selectedTimePeriod}
            onChange={onClickTimePeriod}
            label="All"
        >
            <MenuItem value={TimePeriod.Default} selected>
                {TimePeriod.Default}
            </MenuItem>
            {
                timePeriods.map((timePeriod: string) =>
                    <MenuItem key={timePeriod} value={timePeriod}>
                        {timePeriod}
                    </MenuItem>)
            }
        </Select>
    </Styled.CustomFormControl>
}


export default CompanyPlayersActivitiesFilter
