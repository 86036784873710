import { Add, Info } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const PlayerRankingsTitle = styled(Typography)(({ theme }) => ({
    marginTop: 10,
    padding: '26px 0px 10px 75px',
    fontWeight: 600,
    textAlign: 'center',
    fontSize: '13.5px',
    color: '#464343',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
        padding: '0px 0px 10px 0px',
        textAlign: 'center',
        marginTop: 0,
    },
}))

export const HelpIcon = styled(Info)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '13.5px',
    position: 'absolute',
    top: '28px',
    marginLeft: '3px',
    cursor: 'pointer'
}));

export const PieChartBox = styled(Box)(() => ({
    margin: '20px 0px 30px 0px',
}))

export const DashboardHeaderAndFilterWrapper = styled(Box)({
    margin: '40px 0 28px 0',
    display: 'flex',
    justifyContent: 'space-between'
})

export const Title = styled(Typography)({
    fontSize: '45px'
})

export const FlexWrapper = styled(Grid)(({ theme }) => ({
    display: 'flex',
    gap: 8,
    marginTop: '15px',

    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    },
}))

export const InlineText = styled('span')({
    display: 'inline',
})

export const CompanyDashboardInfoGrid = styled(Grid)(({ theme }) => ({
    marginRight: 6,
    position: 'relative',

    [theme.breakpoints.down('md')]: {
        marginLeft: '0px',
    },
}))

export const GraphTableContainer = styled(Grid)({

})

export const Graph = styled(Grid)({

})

export const Table = styled(Grid)({
})

export const DashboardHeaderButtonAndFilterWrapper = styled(Box)({
    display: 'flex',
    gap: '10px'
})

export const CustomAddIcon = styled(Add)(({ theme }) => ({
	cursor: 'pointer',
	marginRight: '5px',
	fontSize: '25px',
	'&:hover': {
		color: theme.palette.primary.main,
		textDecoration: 'underline',
		cursor: 'pointer',
	},
}))
