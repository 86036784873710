/* eslint-disable no-nested-ternary */
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { CompanyDashboardContext, useCompanyPlayersActivities, useCompanyPlayersActivitiesBarChart, useCompanyPlayersActivitiesGrade, useCompanyPlayersActivitiesPieChart } from "../../api/hooks"
import playBackendRequestHandler from '../../api/playBackendRequestHandler'
import { TimePeriod } from '../../enums'
import ErrorPage from '../../shared/components/ErrorPage/ErrorPage'
import Loader from '../../shared/components/Loader/Loader'
import SecDimButton from '../../shared/components/SecDimButton/SecDimButton'
import Tooltip from '../../shared/components/Tooltip/Tooltip'
import { getStartDateAndEndDateBasedOnTimePeriod } from '../../shared/helpers/utils'
import theme from '../../utils/theme'
import * as Styled from './CompanyDashboardStyles'
import CompanyDashboardInfo from './components/CompanyDashboardInfo/CompanyDashboardInfo'
import CompanyPlayerActivityDialog from './components/CompanyPlayerActivityDialog/CompanyPlayerActivityDialog'
import CompanyPlayersActivitiesBarChart from "./components/CompanyPlayersActivitiesBarChart/CompanyPlayersActivitiesBarChart"
import CompanyPlayersActivitiesFilter from './components/CompanyPlayersActivitiesFilter/CompanyPlayersActivitiesFilter'
import CompanyPlayersActivitiesPieChart from './components/CompanyPlayersActivitiesPieChart/CompanyPlayersActivitiesPieChart'
import CompanyPlayersActivitiesTable from "./components/CompanyPlayersActivitiesTable/CompanyPlayersActivitiesTable"
import { clearSelectedVulnerabilities } from '../../redux/vulnerabilitiesSlice'



function CompanyDashboard() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const fetchCompanyPlayersActivitiesData = useCompanyPlayersActivities()
    const fetchCompanyPlayersActivitiesBarChartData = useCompanyPlayersActivitiesBarChart()
    const fetchCompanyPlayersActivitiesPieChartData = useCompanyPlayersActivitiesPieChart()
    const { data: companyComparativeGradeData, isLoading: isCompanyComparativeGradeDataLoading } = useCompanyPlayersActivitiesGrade()
    const [companyPlayersActivitiesData, setCompanyPlayersActivitiesData] = useState<CompanyPlayersActivities>({ number_of_subscribed_players: 0, player_challenge_attempts: [] })
    const [companyPlayersActivitiesBarChartData, setCompanyPlayersActivitiesBarChartData] = useState<CompanyPlayersActivitiesBarChart[]>([])
    const [companyPlayersActivitiesPieChartData, setCompanyPlayersActivitiesPieChartData] = useState<CompanyPlayersActivitiesPieChart[]>([])
    const [openCompanyPlayerProfileDialogInfo, setOpenCompanyPlayerProfileDialogInfo] = useState<CompanyPlayerActivityInfo>({ isDialogShown: false, playerUsername: "", playerEmail: "" })

    // the following useEffect is for initializing the attempts table based on CompanyPlayersActivities api response
    useEffect(() => {
        if (fetchCompanyPlayersActivitiesData?.data?.data) {
            setCompanyPlayersActivitiesData(fetchCompanyPlayersActivitiesData?.data.data)
        }
    }, [fetchCompanyPlayersActivitiesData?.data])

    // the following useEffect is for initializing the bar chart based on CompanyPlayersActivitiesBarChart api response
    useEffect(() => {
        if (fetchCompanyPlayersActivitiesBarChartData?.data?.data) {
            setCompanyPlayersActivitiesBarChartData(fetchCompanyPlayersActivitiesBarChartData?.data.data)
        }
    }, [fetchCompanyPlayersActivitiesBarChartData?.data])

    // the following useEffect is for initializing the pie chart based on CompanyPlayersActivitiesBarChart api response
    useEffect(() => {
        if (fetchCompanyPlayersActivitiesPieChartData?.data?.data) {
            setCompanyPlayersActivitiesPieChartData(fetchCompanyPlayersActivitiesPieChartData?.data.data)
        }
    }, [fetchCompanyPlayersActivitiesPieChartData?.data])


    // make api call with start date and end date and set the response to the attempts table
    const makeCompanyPlayersActivitiesAPICall = (getStartAndEndDate: any) => {
        playBackendRequestHandler(
            'companyPlayersActivities',
            undefined,
            undefined,
            getStartAndEndDate ? `?start_date=${getStartAndEndDate.startDate}&end_date=${getStartAndEndDate.endDate}` : undefined
        ).then((resp: any) => {
            if (resp.status === 200) {
                setCompanyPlayersActivitiesData(resp.data)
            }
        })
    }

    // make api call with start date and end date and set the response to the map
    const makeCompanyPlayersActivitiesBarChartAPICall = (getStartAndEndDate: any) => {
        playBackendRequestHandler(
            'companyPlayersActivitiesBarChart',
            undefined,
            undefined,
            getStartAndEndDate ? `?start_date=${getStartAndEndDate.startDate}&end_date=${getStartAndEndDate.endDate}` : undefined
        ).then((resp: any) => {
            if (resp.status === 200) {
                setCompanyPlayersActivitiesBarChartData(resp.data)
            }
        })
    }

    // start processing when user selects time period globally
    const processSelectedTimePeriod = (selectedTimePeriod: string) => {
        let getStartAndEndDate = null
        if (TimePeriod.Default !== selectedTimePeriod)
            getStartAndEndDate = getStartDateAndEndDateBasedOnTimePeriod(selectedTimePeriod)

        makeCompanyPlayersActivitiesAPICall(getStartAndEndDate)
        makeCompanyPlayersActivitiesBarChartAPICall(getStartAndEndDate)
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>
        {
            fetchCompanyPlayersActivitiesData.isLoading || fetchCompanyPlayersActivitiesBarChartData.isLoading || fetchCompanyPlayersActivitiesPieChartData.isLoading || isCompanyComparativeGradeDataLoading ?
                <Loader /> :
                fetchCompanyPlayersActivitiesData.isError || !fetchCompanyPlayersActivitiesData.isSuccess || fetchCompanyPlayersActivitiesBarChartData.isError || !fetchCompanyPlayersActivitiesBarChartData.isSuccess || fetchCompanyPlayersActivitiesPieChartData.isError || !fetchCompanyPlayersActivitiesPieChartData.isSuccess ?
                    <ErrorPage
                        errorIconName='Block'
                        errorTitle='Company Dashboard'
                        errorMessage={fetchCompanyPlayersActivitiesData.error ? (fetchCompanyPlayersActivitiesData.error as any).response.data.detail : fetchCompanyPlayersActivitiesBarChartData.error ? (fetchCompanyPlayersActivitiesBarChartData.error as any).response.data.detail : fetchCompanyPlayersActivitiesPieChartData.error ? (fetchCompanyPlayersActivitiesPieChartData.error as any).response.data.detail : ""}
                    /> :
                    <CompanyDashboardContext.Provider
                        // eslint-disable-next-line react/jsx-no-constructed-context-values
                        value={{
                            openCompanyPlayerProfileDialogInfo,
                            setOpenCompanyPlayerProfileDialogInfo,
                        }}>
                        {openCompanyPlayerProfileDialogInfo.isDialogShown && <CompanyPlayerActivityDialog companyPlayersActivitiesPieChartData={companyPlayersActivitiesPieChartData} />}
                        <Styled.DashboardHeaderAndFilterWrapper>
                            <Styled.Title variant='h1'>{companyComparativeGradeData?.data.company_name}</Styled.Title>
                            <Styled.DashboardHeaderButtonAndFilterWrapper>
                                <SecDimButton rounded variant="outlined" sx={{
                                    backgroundColor: theme.palette.common.white,
                                    color: theme.palette.primary.main,
                                }} onClick={() => {
                                    dispatch(clearSelectedVulnerabilities())
                                    navigate('/create/game')
                                }
                                }><Styled.CustomAddIcon />Create Game</SecDimButton>
                                <CompanyPlayersActivitiesFilter processSelectedTimePeriod={processSelectedTimePeriod} />
                            </Styled.DashboardHeaderButtonAndFilterWrapper>
                        </Styled.DashboardHeaderAndFilterWrapper>
                        <CompanyDashboardInfo companyPlayersActivitiesData={companyPlayersActivitiesData} companyComparativeGradeData={companyComparativeGradeData?.data!} />
                        <Styled.GraphTableContainer container>
                            <Styled.Graph item xs={12} sm={5}>
                                <CompanyPlayersActivitiesBarChart companyPlayersActivitiesBarChartData={companyPlayersActivitiesBarChartData} />
                                <Styled.PieChartBox><CompanyPlayersActivitiesPieChart companyPlayersActivitiesPieChartData={companyPlayersActivitiesPieChartData} /></Styled.PieChartBox>
                            </Styled.Graph>
                            <Styled.Table item xs={12} sm={7}>
                                <Styled.PlayerRankingsTitle variant='h4'>Team Engagements
                                    <Tooltip title="Click on each row to view the user details"
                                        placement='top-start'>
                                        <Styled.HelpIcon />
                                    </Tooltip>
                                </Styled.PlayerRankingsTitle>
                                <CompanyPlayersActivitiesTable companyPlayersActivitiesData={companyPlayersActivitiesData.player_challenge_attempts} />
                            </Styled.Table>
                        </Styled.GraphTableContainer >
                    </CompanyDashboardContext.Provider>
        }
    </>
}


export default CompanyDashboard
