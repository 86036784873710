import { useEffect, useState } from 'react';
import * as Styled from './CompanyPlayerActivityDialogStyles'
import { useCompanyDashboardContext, useCompanyPlayerGitHistoryNotificationWS, useWebsocketContext } from '../../../../api/hooks'
import RemoveCompanyPlayerConfirmationDialog from './components/RemoveCompanyPlayerConfirmationDialog/RemoveCompanyPlayerConfirmationDialog';
import CompanyPlayersActivitiesPieChart from '../CompanyPlayersActivitiesPieChart/CompanyPlayersActivitiesPieChart';
import CompanyPlayerRepositoriesTable from './components/CompanyPlayerRepositoriesTable/CompanyPlayerRepositoriesTable';
import CompanyPlayerGitHeatmap from './components/CompanyPlayerGitHeatmap/CompanyPlayerGitHeatmap';
import playBackendRequestHandler from '../../../../api/playBackendRequestHandler';
import Tooltip from '../../../../shared/components/Tooltip/Tooltip';


function CompanyPlayerActivityDialog({
	className,
	companyPlayersActivitiesPieChartData
}: CompanyPlayerActivityDialogProps) {
	const [openRemoveCompanyPlayerConfirmationDialogInfo, setOpenRemoveCompanyPlayerConfirmationDialogInfo] = useState<CompanyPlayerActivityInfo>({ isDialogShown: false, playerUsername: "", playerEmail: "" })
	const { openCompanyPlayerProfileDialogInfo, setOpenCompanyPlayerProfileDialogInfo } = useCompanyDashboardContext()
	const { isWSConnectionEstablished } = useWebsocketContext() // this is global ws connection variable
	const { companyPlayerGitHistoryWS, setIsWSConnectionEstablished } = useCompanyPlayerGitHistoryNotificationWS()
	const [companyPlayerHeatMap, setCompanyPlayerHeatMap] = useState<HeatMap[]>([])

	const handleCloseDialog = () => {
		setOpenCompanyPlayerProfileDialogInfo({
			isDialogShown: false,
			playerUsername: "",
			playerEmail: "",
		})
	}

	const handleCloseRemoveCompanyPlayerConfirmationDialog = () => {
		setOpenRemoveCompanyPlayerConfirmationDialogInfo({
			isDialogShown: false,
			playerUsername: "",
			playerEmail: "",
		})
	}
	const handleRemoveUserButton = () => {
		setOpenRemoveCompanyPlayerConfirmationDialogInfo({
			isDialogShown: true,
			playerUsername: openCompanyPlayerProfileDialogInfo.playerUsername,
			playerEmail: openCompanyPlayerProfileDialogInfo.playerEmail,
		})
	}

	const makeCompanyPlayerGitHistoryApiCall = (selectedPlayerUsername: string, selectedPlayerEmail: string) => playBackendRequestHandler(
		'companyPlayerGitHistory',
		undefined,
		undefined,
		`?company_player_username=${selectedPlayerUsername}&company_player_email=${selectedPlayerEmail}`
	)

	useEffect(() => {
		if (isWSConnectionEstablished && companyPlayerGitHistoryWS) {
			// set heatmap once it arrives through ws
			setCompanyPlayerHeatMap(companyPlayerGitHistoryWS)
		}
	}, [companyPlayerGitHistoryWS, isWSConnectionEstablished])

	useEffect(() => {
		// set setIsWSConnectionEstablished value true, once ws is globally established (isWSConnectionEstablished=true)
		if (isWSConnectionEstablished) {
			setIsWSConnectionEstablished(isWSConnectionEstablished)
			makeCompanyPlayerGitHistoryApiCall(openCompanyPlayerProfileDialogInfo.playerUsername, openCompanyPlayerProfileDialogInfo.playerEmail)
		}
	}, [isWSConnectionEstablished, setIsWSConnectionEstablished, openCompanyPlayerProfileDialogInfo])

	return (
		<>
			{openRemoveCompanyPlayerConfirmationDialogInfo.isDialogShown && <RemoveCompanyPlayerConfirmationDialog openRemoveCompanyPlayerConfirmationDialogInfo={openRemoveCompanyPlayerConfirmationDialogInfo} handleCloseRemoveCompanyPlayerConfirmationDialog={handleCloseRemoveCompanyPlayerConfirmationDialog} />}
			<Styled.CustomDialog maxWidth="lg" onClose={handleCloseDialog} open={openCompanyPlayerProfileDialogInfo.isDialogShown} className={className}>
				<Styled.AbsoluteClose onClick={handleCloseDialog} />
				<Styled.PlayerActivityDialogContainer container direction="column">
					<Styled.PlayerActivityGridDialogItem item sx={{ paddingBottom: '10px' }}>
						<Styled.DialogTitleText>{openCompanyPlayerProfileDialogInfo.playerEmail} &#40;{openCompanyPlayerProfileDialogInfo.playerUsername}&#41;</Styled.DialogTitleText>
					</Styled.PlayerActivityGridDialogItem>
					<Styled.PlayerActivityGridDialogItem item>
						<CompanyPlayerGitHeatmap companyPlayerHeatMap={companyPlayerHeatMap} />
					</Styled.PlayerActivityGridDialogItem>
					<Styled.PlayerActivityGridDialogItem item >
						<Styled.PlayerActivityGraphTableContainer container direction="row">
							<Styled.PlayerActivityGraphTableItem item xs={12} sm={4}>
								<CompanyPlayersActivitiesPieChart companyPlayersActivitiesPieChartData={companyPlayersActivitiesPieChartData.filter((item: CompanyPlayersActivitiesPieChart) => item.player_username === openCompanyPlayerProfileDialogInfo.playerUsername)} />
							</Styled.PlayerActivityGraphTableItem>
							<Styled.PlayerActivityGraphTableItem item xs={12} sm={8}>
								<Styled.PlayerRankingsTitle variant='h4'>Player Repositories
									<Tooltip title="Click on each row to view the user challenge code repository"
										placement='top-start'>
										<Styled.HelpIcon />
									</Tooltip>
								</Styled.PlayerRankingsTitle>
								<CompanyPlayerRepositoriesTable />
							</Styled.PlayerActivityGraphTableItem>
						</ Styled.PlayerActivityGraphTableContainer>
					</Styled.PlayerActivityGridDialogItem>
					<Styled.PlayerActivityGridDialogItem item >
						<Styled.RemoveUserButton variant="outlined" onClick={handleRemoveUserButton}>
							Remove User
						</Styled.RemoveUserButton>
					</Styled.PlayerActivityGridDialogItem>
				</Styled.PlayerActivityDialogContainer>
			</Styled.CustomDialog >
		</>
	)
}
export default CompanyPlayerActivityDialog
