import { Button as MuiButton } from '@mui/material'
import { styled } from '@mui/material/styles'

// eslint-disable-next-line import/prefer-default-export
export const Button = styled(MuiButton)<{ rounded: number }>(
	({ rounded, theme }) => ({
		backgroundColor: theme.palette.primary.main,
		borderRadius: rounded ? '5px' : '0',
		color: theme.palette.common.white,

		'&:hover': {
			backgroundColor: theme.palette.common.white,
			color: theme.palette.primary.main,
		},
	})
)
